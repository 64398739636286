import { getUser } from "../igps-client-root-config";
import { ErrorModel } from "../model/errorModel";
import { getHeaders } from "./http-interceptor-service";
import { logErrors } from "./logger.service";

export async function getUserData() {
  const url = process.env.ECLIPSE_API + "employee";
  return fetch(url, {
    method: "GET",
    headers: await getHeaders(url, null),
  })
    .then(async (result) => {
      if (result.ok) {
        return result.json();
      } else {
        return Promise.reject(result);
      }
    })
    .catch(async (err) => {
      if (err.status == 503 || err.status == 404 || err.status == 403) {
        const userData = {
          "status":+err.status,
          "id": "NA",
          "homeOffice": {
            "name": "NA"
          }
        }
        return userData;
      }
      const errorData: ErrorModel = {
        loggedInUserName: getUser() ? getUser() : "",
        applicationName: "IGPS-HOST",
        url: url,
        error: err.statusText + err.status ? err.statusText + err.status : "",
      };
      logErrors(errorData);
    });
}
