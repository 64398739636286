import { BehaviorSubject } from "rxjs";
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { getToken } from "../igps-client-root-config";

export let wsConnectDisconnectEvent = new BehaviorSubject<{ status: string }>({ status: "Disconnected" });
export let wsMessage$: WebSocketSubject<any>;
export let messageSubject$ = new BehaviorSubject<any>(null);

export async function connectWebSocket() {
    const url = process.env.WEBSOCKET_URL + `${await getToken()}`;
    wsMessage$ = webSocket(url);
    wsMessage$.subscribe({
        next: msg => {
            messageSubject$.next(msg);
        },
        error: async (err) => {
            wsConnectDisconnectEvent.next({ status: "Disconnected" });
            await connectWebSocket();
        },
        complete: async () => {
            wsConnectDisconnectEvent.next({ status: "Disconnected" });
            await connectWebSocket();
        }
    });
    wsConnectDisconnectEvent.next({ status: "Connected" });
}

