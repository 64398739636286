export enum CommonMessages {
    Success = 'Your changes are saved',
    Error = 'Your changes are not saved',
    NoChanges='No changes to save',
    UnsavedChangesMessage = 'You have unsaved work that has not been submitted. Would you like to continue?',
    IGPSApiServerErrorMessage = 'Internal server error in IGPS API. Please re-try or report the incident if the issue persists.',
    InternalServerErrorMessage = 'Internal server error. Please re-try or report the incident if the issue persists.',
    ExceptionMessage = 'Server operation failed. Please re-try or report the incident if the issue persists.',
    TextInfoMessage='Name should be unique with more than one character, space allowed, no special characters allowed and should start with an alphabet. Max 50 characters allowed',
    EmailValidationError='Please enter valid Email',
    EmailNotFound='User details not found',
    TaskCannotBeAssigned ="User cannot be assigned to task without giving access",
    DeleteConfirm='Are you sure you want to delete?',
    DeleteSuccess='Record deleted successfully',
    IgniteNotesError='Unable to load Ignite task notes'
}

export enum Role{
    Processor = '250'
}

export enum Site {
    Onshore = 'onshore'
}
export enum AssumedRole{
    Manager = 1,
    Processor = 2
}