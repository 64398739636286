import { BehaviorSubject } from "rxjs";

export let timezoneSubject$ = new BehaviorSubject<{ name: string; value: string; abbreviation: string }>({ name: "", value: "local", abbreviation: "" });

export function getTimezone(): { name: string; value: string; abbreviation: string } {
    return timezoneSubject$.value;
}

export function setTimezone(value: { name: string; value: string; abbreviation: string }) {
    timezoneSubject$.next(value);
}

