import { getHeaders } from "../igps-client-root-config";
import { ErrorModel } from "../model/errorModel";

const loggerServiceUrl = process.env.IGPS_API + "logger/log/v1";

export const logErrors = async (errorData: ErrorModel) => {
  return fetch(loggerServiceUrl, {
    method: "POST",
    headers: await getHeaders(loggerServiceUrl, null),
    body: JSON.stringify(errorData),
  }).then(async (result) => {
    if (result.ok) {
      return result.json();
    }
  });
};
