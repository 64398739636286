import { getUser } from "../igps-client-root-config";
import { ErrorModel } from "../model/errorModel";
import { getHeaders } from "./http-interceptor-service";
import { logErrors } from "./logger.service";

const errorData: ErrorModel = {
  loggedInUserName: "",
  applicationName: "IGPS-HOST",
  url: "",
  error: "",
};

export async function getLoggedInUserData() {
  const url = process.env.IGPS_API + `staffing/staff/v1/me`;
  return fetch(url, {
    method: "GET",
    headers: await getHeaders(url, null),
  })
    .then(async (result) => {
      if (result.ok) {
        return result.json();
      } else {
        return Promise.reject(result);
      }
    })
    .catch(async (err) => {
      (errorData.url = url), (errorData.loggedInUserName = getUser());
      errorData.error = err.status + err.statusText;
      logErrors(errorData);
    });
}

export async function updateLoginInformation() {
  const url = process.env.IGPS_API + "staffing/staff/v1/login/update";
  return fetch(url, {
    method: "PUT",
    headers: await getHeaders(url, null),
    body: null,
  })
    .then(async (result) => {
      if (result.ok) {
        return result.json();
      } else {
        return Promise.reject(result);
      }
    })
    .catch(async (err) => {
      (errorData.url = url), (errorData.loggedInUserName = getUser());
      errorData.error = err.status + err.statusText;
      logErrors(errorData);
    });
}
