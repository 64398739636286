import { getToken } from "../igps-client-root-config";

export const getHeaders = async (url: string, body: any) => {
  let headers = {
    "Cache-control": "no-cache",
    "Content-Type": "application/json",
    Expires: "0",
    Pragma: "no-cache",
    originapp: 'client',
    "Correlation-Id": "00000000-0000-0000-0000-000000000000", //Empty value for eclipse
    Authorization: `Bearer ${await getToken()}`,
  };
  return headers;
};
