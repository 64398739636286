import { Payload, IPostMessage } from './model/wsSendMessage';
import { registerApplication, start } from "single-spa";
import {
  PublicClientApplication,
  AuthenticationResult,
  AccountInfo,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import igniteClientLayout from "./layouts/igps-client-layout.html";
import igniteClientAccessErrorLayout from "./layouts/igps-client-access-error.html";
import { getUserData } from "./services/igps-eclipse-service";
import {
  getLoggedInUserData,
  updateLoginInformation,
} from "./services/igps-service";
import { UserInfo } from "./model/userinfo";
import { AssumedRole } from "./enums/igps-common.enum";
import { connectWebSocket, wsMessage$, messageSubject$, wsConnectDisconnectEvent } from "./services/websocket-service";
import { BehaviorSubject, Observable } from "rxjs";
import { timezoneSubject$ } from './services/timezone-service';

let accessToken!: string;
let userInfo!: UserInfo;
let homeOffice: string;
let fastuserstatus: string = "OK";
let loginName: string;
const refreshTokenBefore_MinutesOfExpiry: number = 10;
let timeOut!: NodeJS.Timeout;
const scopes = [`${process.env.MSAL_CLIENT_ID}/.default`];
const client = new PublicClientApplication({
  auth: {
    clientId: process.env.MSAL_CLIENT_ID,
    authority:
      "https://login.microsoftonline.com/4cc65fd6-9c76-4871-a542-eb12a5a7800c",
    redirectUri: process.env.MSAL_REDIRECT_URI,
  },
});
let account: AccountInfo = client.getAccountByUsername(
  window.sessionStorage.getItem("username")
);

client.handleRedirectPromise()
  .then(async (tokenResponse: AuthenticationResult) => {
    if (tokenResponse) {
      sessionStorage.removeItem("IsInteractionInProgress");
      accessToken = tokenResponse.accessToken;
      refreshToken(refreshTokenBefore_MinutesOfExpiry);
      await startApplications();
      try {
        await updateLoginInformation();
      } catch (error) { }
    }
  });

if (!(!!sessionStorage.getItem("IsInteractionInProgress"))) {
  if (account) {
    getTokenAtRefreshScreen();
    refreshToken(refreshTokenBefore_MinutesOfExpiry);
  } else {
    sessionStorage.setItem("IsInteractionInProgress", "true");
    client.loginRedirect({ scopes });
  }
}

function getTokenAtRefreshScreen() {
  client.acquireTokenSilent({ scopes, account, forceRefresh: true }).then((tokenResponse: AuthenticationResult) => {
    accessToken = tokenResponse.accessToken;
    (async () => await startApplications())();
  }).catch(error => {
    if (error instanceof InteractionRequiredAuthError) {
      return client.acquireTokenRedirect({ scopes, loginHint: account.username });
    }
  });
}

function refreshToken(refreshBeforeMinutes: number, timeOutInMilliseconds: number = 0) {
  timeOut = setTimeout(() => {
    client.acquireTokenSilent({ scopes, account, forceRefresh: true }).then((tokenResponse: AuthenticationResult) => {
      accessToken = tokenResponse.accessToken;
      refreshToken(refreshBeforeMinutes, ((tokenResponse.expiresOn.getTime() - new Date().getTime()) - (refreshBeforeMinutes * 60000)));
    }).catch(error => {
      if (error instanceof InteractionRequiredAuthError) {
        return client.acquireTokenRedirect({ scopes, loginHint: account.username });
      }
    });
  }, timeOutInMilliseconds);
}

async function startApplications() {
  await setupIgnitePS();
  let routes = constructRoutes(igniteClientLayout);
  if (homeOffice == "" || !homeOffice || !userInfo) {
    routes = constructRoutes(igniteClientAccessErrorLayout);
  }
  const applications = constructApplications({
    routes,
    loadApp({ name }) {
      return System.import(name);
    },
  });
  applications.forEach(registerApplication);
  constructLayoutEngine({ routes, applications }).activate();
  start();
  await redirectToDashBoard();
}
async function setupIgnitePS() {
  await setUserAccount();
}
async function setUserAccount() {
  account = client.getAllAccounts()[0];
  if (account.idTokenClaims != null) {
    const claims: any = account["idTokenClaims"];
    loginName = claims.onpremADusername;
    await checkFastAccess();
  }
  window.sessionStorage.setItem("username", account.username);
}

async function checkFastAccess() {
  var userData = await getUserData();
  if (userData != null) {
    if (userData || userData.id >= 0) {
      homeOffice = userData.homeOffice.name;

      if (userData.id == "NA") {
        if (userData.status == 404)
          fastuserstatus = "NOTFOUND";
        else if (userData.status == 503)
          fastuserstatus = "SERVICEUNAVAILABLE";
        else if (userData.status == 403)
          fastuserstatus = "INACTIVE";
      }
      await getLoggedInUserInfo();
    }
  }
}
async function getLoggedInUserInfo() {
  userInfo = await getLoggedInUserData();
  await connectWebSocket();
}

async function redirectToDashBoard() {
  const origin = `${window.location.origin}`;
  const managerUrl = `/manager/dashboard`;
  const processorUrl = `/processor/dashboard`;
  if (window.location.pathname == "/") {
    if (userInfo.assumedRoleId === AssumedRole.Manager) {
      window.location.href = origin.concat(managerUrl);
    }
    else {
      window.location.href = processorUrl;
    }
  } else if (window.location.pathname == managerUrl) {
    if (userInfo.assumedRoleId === AssumedRole.Manager) {
      return;
    }
    else {
      window.location.href = origin.concat(processorUrl);
    }
  } else if (window.location.pathname == processorUrl) {
    if (userInfo.assumedRoleId === AssumedRole.Processor) {
      return;
    }
    else {
      window.location.href = origin.concat(managerUrl);
    }
  }
}

function sendWSMsg(payload: Payload) {
  const postMessage: IPostMessage = {
    action: "sendmessage",
    payload: payload
  }
  wsMessage$.next(postMessage);
}

export function ws_sendMessage(payload: Payload) {
  if (!wsMessage$ || wsMessage$.closed) {
    wsConnectDisconnectEvent.next({ status: "Disconnect" });
    connectWebSocket().then(() => {
      sendWSMsg(payload);
    });
  }
  else {
    sendWSMsg(payload);
  }
}




export { showSidebar } from './services/sidebar-service';
export { hideSidebar } from './services/sidebar-service';
export { isSidebarOpen } from './services/sidebar-service';
export const getUser = (): string => account.name;
export const getFASTOffice = (): string => homeOffice;
export const getFASTUserStatus = (): string => fastuserstatus;
export const getUserDomainName = (): string => loginName;
export const getUserInfo = (): UserInfo => userInfo;
export { getHeaders } from "./services/http-interceptor-service";
export const getToken = async (): Promise<string> => Promise.resolve(accessToken);
export { CommonMessages } from "./enums/igps-common.enum";
export const ipsWsMessages$ = messageSubject$.asObservable();
export const wsStatus$: Observable<{ status: string }> = wsConnectDisconnectEvent.asObservable();
export const timezone$ = timezoneSubject$.asObservable();
export { getTimezone, setTimezone } from './services/timezone-service';