let _isSidebarOpen = true;

// eslint-disable-next-line
export const showSidebar = () => {
  const centerPanel = document.getElementsByClassName('center-panel')[0];
  const sidebar = document.getElementById('sidebar');
  sidebar.classList.remove('hide');
  centerPanel.classList.remove('without-sidebar');
  _isSidebarOpen = true;
};

// eslint-disable-next-line
export const hideSidebar = () => {
  const centerPanel = document.getElementsByClassName('center-panel')[0];
  const sidebar = document.getElementById('sidebar');
  sidebar.classList.add('hide');
  centerPanel.classList.add('without-sidebar');
  _isSidebarOpen = false;
};

// eslint-disable-next-line
export const isSidebarOpen = () => _isSidebarOpen;
